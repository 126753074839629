const colorMatrix = [
  { name: "edelstahl", hex: "#D0CFCC" },
  { name: "altrosa", hex: "#d47479" },
  { name: "anthrazit", hex: "#081430" },
  { name: "beere", hex: "#98003c" },
  { name: "salbei", hex: "#B2AC88" },
  { name: "sand", hex: "#c2b280" },
  { name: "blau", hex: "#0000ff" },
  { name: "braun", hex: "#964B00" },
  { name: "cognac", hex: "#bb6144" },
  { name: "creme", hex: "#efebdc" },
  { name: "d", hex: "#5C4033" },
  { name: "dunkelblau", hex: "#00008b" },
  { name: "dunkelbraun", hex: "#5C4033" },
  { name: "dunkelgrau", hex: "#A9A9A9" },
  { name: "dunkelgrün", hex: "#013220" },
  { name: "olivgrün", hex: "#013220" },
  { name: "eisblau", hex: "#bddeec" },
  { name: "fuchsia", hex: "#FF00FF" },
  { name: "gelb", hex: "#FFFF00" },
  { name: "grau", hex: "#808080" },
  { name: "hellbraun", hex: "#C4A484" },
  { name: "khaki", hex: "#c3b091" },
  { name: "lindgrün", hex: "#1ad665" },
  { name: "mint", hex: "#aaf0d1" },
  { name: "natur", hex: "#E4C9A8" },
  { name: "ocker", hex: "#b88428" },
  { name: "oliv", hex: "#556b2f" },
  { name: "rosa", hex: "#F33A6A" },
  { name: "rot", hex: "#FF0000" },
  { name: "schwarz", hex: "#000000" },
  { name: "stein", hex: "#B7B09C" },
  { name: "taupe", hex: "#483C32" },
  { name: "transparent", hex: "transparent" },
  { name: "weiß", hex: "#eeeeee" },
  { name: "orange", hex: "#FFA500" },
  { name: "pastellrot", hex: "#FF6961" },
  { name: "petrol", hex: "#005f6a" },
  { name: "hellgrau", hex: "#d3d3d3" },
  { name: "lichtgrau", hex: "#d3d3d3" },
  { name: "beige", hex: "#e1c699" },
  { name: "türkis", hex: "#40E0D0" },
  { name: "grafit", hex: "#251607" },
  { name: "graphit", hex: "#251607" },
  { name: "ozean", hex: "#064273" },
  { name: "aqua", hex: "#064273" },
  { name: "silber", hex: "#c0c0c0" },
  { name: "grün", hex: "#008000" },
  { name: "hellblau", hex: "#ADD8E6" },
  { name: "mint", hex: "#aaf0d1" },
  { name: "catnip", hex: "#aaf0d1" },
  { name: "limette", hex: "#32cd32" },
  { name: "pink", hex: "#FFC0CB" },
  { name: "anthrazit", hex: "#081430" },
  { name: "meliert", hex: "#222222" },
  { name: "tabak", hex: "#6D5843" },
  { name: "violett", hex: "#483C32" },
  { name: "flieder", hex: "#9f97fc" },
  { name: "platingrau", hex: "#7f7679" },
  { name: "neonorange", hex: "#fd4825" },
  { name: "mocca", hex: "#a38068" },
  { name: "papaya", hex: "#d47205" },
  { name: "leopard", hex: "#ffaf4d" },
  { name: "neongelb", hex: "#fdfc60" },
  { name: "sterne", hex: "#E1D9D1" },
  { name: "zink", hex: "#bac4c8" },
  { name: "cappuccino", hex: "#4b3832" },
  { name: "coral", hex: "#FF7F50" },
  { name: "hummer", hex: "#b62b2b" },
  { name: "karamell", hex: "#80532d" },
  { name: "pflaume", hex: "#DDA0DD" },
  { name: "himbeere", hex: "#b42041" },
  { name: "champagner", hex: "#dec19e" },
  { name: "koriander", hex: "#888D33" },
  { name: "silberweiß", hex: "#C0C0C0" },
  { name: "hafer", hex: "#efddb4" },
  { name: "baldrian", hex: "#9f7a93" },
  { name: "marmor", hex: "#e6dec8" },
  { name: "angel", hex: "#ffd39b" },
  { name: "schlamm", hex: "#70543e" },
  { name: "gold", hex: "#d4af37" },
  { name: "burgunder", hex: "#ac3428" },

  {
    name: "weiß/grau",
    hex: "linear-gradient(-45deg, #ffffff 50%, #808080 50%)",
  },
];

const findColor = (val) => {
  let valArr = val.replace("-", "/").split("/");

  if (valArr && valArr.length == 1) {
    const color = colorMatrix.find((item) => item.name === valArr[0]);
    if (color && color.hex) {
      return color.hex;
    } else {
      // console.log("Color Missing: ");
      // console.log(valArr[0]);
    }
  } else if (valArr && valArr.length == 2) {
    const color1 = colorMatrix.find((item) => item.name === valArr[0]);
    const color2 = colorMatrix.find((item) => item.name === valArr[1]);
    if (color1 && color1.hex && color2 && color2.hex) {
      return `linear-gradient(-45deg, ${color1.hex} 50%, ${color2.hex} 50%)`;
    } else {
      // console.log("One or more of these two colors Missing: ");
      // console.log(valArr[0]);
      // console.log(valArr[1]);
    }
  } else if (valArr && valArr.length == 3) {
    const color1 = colorMatrix.find((item) => item.name === valArr[0]);
    const color2 = colorMatrix.find((item) => item.name === valArr[1]);
    const color3 = colorMatrix.find((item) => item.name === valArr[2]);
    if (color1 && color1.hex && color2 && color2.hex && color3 && color3.hex) {
      return `linear-gradient(-45deg, ${color1.hex} 33.33%, ${color2.hex} 33.33%,${color2.hex} 66.66%, ${color3.hex} 66.66%)`;
    } else {
      // console.log("One or more of these three colors Missing: ");
      // console.log(valArr[0]);
      // console.log(valArr[1]);
      // console.log(valArr[2]);
    }
  }
};

export { findColor };
